import {Mail} from "./Mail";
import {Contact} from "./Contact";
import {User} from "./User";
import {FileDto} from "./File";
import {EventUserSpeaker} from "./EventUserSpeaker";
import {EventContactSpeaker} from "./EventContactSpeaker";
import {MeetingRoom} from "./MeetingRoom";
import {GroupEvent} from "./GroupEvent";
import {EventUserAdditionalGuest} from "./EventUserAdditionalGuest";
import {EventContactAdditionalGuest} from "./EventContactAdditionalGuest";
import {UploadedFile} from "./File";
import {UploadableFile} from "../common/file/UploadableFile";

export enum EventStatus {
    DRAFT = "DRAFT",
    MEETING_ROOM_RESERVED = "MEETING_ROOM_RESERVED",
    INVITATIONS_SENT = "INVITATIONS_SENT",
    FINISHED = "FINISHED"
}

export enum AttendanceType {
    ON_SITE = "ON_SITE",
    DISTANCE = "DISTANCE",
    HYBRID = "HYBRID"
}

export interface Event {
    id: string;
    createdAt: string;
    updatedAt: string;
    description: string;
    status: EventStatus;
    teamsLink: string;
    teamsId: string;
    startAt: string;
    endAt: string;
    teamsFileImportedAt: string;
    attendanceType: AttendanceType;
    mail?: Mail;
    author?: User;
    contactAnimator?: Contact;
    userAnimator?: User;
    eventUserSpeakers?: Array<EventUserSpeaker>;
    eventContactSpeakers?: Array<EventContactSpeaker>;
    meetingRoom?: MeetingRoom;
    groupEvents?: Array<GroupEvent>;
    eventUserAdditionalGuests?: Array<EventUserAdditionalGuest>;
    eventContactAdditionalGuests?: Array<EventContactAdditionalGuest>;
    displayStatus?: string;
    displayAttendanceType?: string;
    isLinkedWithCommitteeOrWorkshop?: boolean;
    exchangeCalendarId?: string;
    exchangeCalendarChangeKey?: string;
    eventStatistics:{[key: string]: number};
    registeredContactsNb?: number;
}

export type ReadonlyEvent = Readonly<Event>;

export interface EventDto {
    subject: string;
    content: string;
    attachments: Array<UploadedFile | UploadableFile>;
    description: string;
    authorId: string;
    contactAnimatorId: string;
    userAnimatorId: string;
    startAt: string;
    endAt: string;
    meetingRoomId: string;
    groupIds: Array<string>;
    userSpeakerIds: Array<string>;
    contactSpeakerIds: Array<string>;
    userAdditionalGuestIds: Array<string>;
    contactAdditionalGuestIds: Array<string>;
    attendanceType: string;
}

export interface ManageExchangeCalendarEventDto {
    meetingRoomId: string;
    startAt: string;
    endAt: string;
}

export interface ImportEventVerifiedPresencesFromTeamsDto {
    teamsFile: FileDto;
}

export function isEventFinished(event: ReadonlyEvent): boolean {
    return event.status === EventStatus.FINISHED;
}

export function isHybridEvent(event: ReadonlyEvent): boolean {
    return event.attendanceType === AttendanceType.HYBRID;
}

export function isOnSiteEvent(event: ReadonlyEvent): boolean {
    return event.attendanceType === AttendanceType.ON_SITE;
}

export function isDistanceEvent(event: ReadonlyEvent): boolean {
    return event.attendanceType === AttendanceType.DISTANCE;
}

export function isMeetingRoomReserved(event: ReadonlyEvent): boolean {
    return event.status === EventStatus.MEETING_ROOM_RESERVED;
}

export function isDraftEvent(event: ReadonlyEvent): boolean {
    return event.status === EventStatus.DRAFT;
}

export function hasNoExchangeCalendarEvent(event: ReadonlyEvent): boolean {
    return !event.exchangeCalendarId;
}

export function reachedInvitationsSent(event: ReadonlyEvent): boolean {
    return event.status === EventStatus.INVITATIONS_SENT || event.status === EventStatus.FINISHED;
}

export function isEventPassed(event: ReadonlyEvent): boolean {
    return new Date(event.endAt) < new Date();
}