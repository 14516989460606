import apiClient from "./apiClient";
import {buildRelationIncludesUrl} from "./detail";

export async function fetchCurrentUser(relationIncludes: Array<string> = []) : Promise<Response> {
    return await fetch(
        buildRelationIncludesUrl(`/api/users/current`, relationIncludes), {
            method: 'GET'
        }
    );
}

export async function logIn(email: string, password: string): Promise<Response> {
    return await fetch('/api/login', {
        method: 'POST',
        body: JSON.stringify({
            email,
            password
        }),
    })
}

export async function logOut(): Promise<Response> {
    return await fetch('/api/logout');
}

export async function requestPasswordReset(email: string): Promise<Response> {
    return await fetch('/api/request_password_reset', {
        method: 'POST',
        body: JSON.stringify({
            email,
        }),
    });
}

export async function passwordResetLinkChecker(token: string): Promise<Response> {
    return await fetch(`/api/reset_link_checker/${token}`, {
        method: 'GET'
    });
}

export async function resetPassword(password: string, token: string): Promise<Response> {
    return await fetch('/api/reset_password', {
        method: 'POST',
        body: JSON.stringify({
            resetToken: token,
            newPassword: password,
        }),
    });
}
