import React from "react";
import { Box } from "@chakra-ui/react";

export default function DefaultLayout({ children }) {
  return (
    <Box
      width={["100%", "calc(100% - 18.75rem)"]}
      position={["absolute", null]}
      top={["4em", 0]}
      left={[0, "18.75rem"]}
      p={[4, 16]}
    >
      {children}
    </Box>
  );
}
