import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    borderRadius: 5,
    border: "2px solid",
    fontWeight: "600",
    background: "transparent",
    _placeholder: {
      fontWeight: "600",
      color: "gray.base",
    },
    _focus: {
      borderColor: "brand.300",
    },
    _invalid: {
      borderColor: "error",
      color: "error",
    },
  },
});

const primary = definePartsStyle({
  field: {
    borderColor: "gray.base",
    color: "brand.300",
  },
});

export const inputTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { primary },
  defaultProps: {
    variant: "primary",
  },
});
