import React from "react";

import {
  Avatar,
  Box,
  Flex,
  Icon,
  Link,
  List,
  ListIcon,
  ListItem,
  VStack,
} from "@chakra-ui/react";
import * as NextLink from "next/link";

import { PiHouse, PiList, PiPlus } from "react-icons/pi";
import logo from "../assets/images/ilec-logo-white.svg";
import { css, Global } from "@emotion/react";
import Image from "next/image";

export default function Navbar(props) {
  return <>{props.children}</>;
}

export function NavbarHeader({ open, setOpen }) {
  return (
    <Flex
      align="center"
      justify="space-between"
      position="fixed"
      zIndex={["2000", null]}
      width={["100%", "18.75rem"]}
      height={["4rem", null]}
      backgroundColor="brand.500"
      px={3}
    >
      <NextLink href="/">
        <Flex align="center" gap={4}>
          <Icon as={PiHouse} boxSize="2em" pt={1} color="white" />
          <Image src={logo} width={40} height={40} alt={"Logo"} />
        </Flex>
      </NextLink>

      <Icon
        as={PiList}
        boxSize="2em"
        cursor="pointer"
        color="white"
        display={[open ? "none" : null, "none"]}
        onClick={() => setOpen(!open)}
      />

      <Icon
        as={PiPlus}
        boxSize="2em"
        cursor="pointer"
        color="white"
        transform="rotate(45deg)"
        display={[!open ? "none" : null, "none"]}
        onClick={() => setOpen(!open)}
      />
    </Flex>
  );
}

// Permet de sortir la nav de l'écran tant que le bouton n'est pas cliqué
let smallScreensLeft = "-105%";

export function NavbarBody({ topMenu, bottomMenu, open }) {
  return (
    <>
      <Global
        styles={css`
          body {
            @media all and (max-width: 425px) {
              overflow: ${open ? "hidden" : null};
            }
            @media all and (min-width: 426px) {
              overflow: "scroll";
            }
          }
        `}
      />

      <VStack
        align="flex-start"
        justify="space-between"
        backgroundColor="white"
        boxShadow="base"
        width={["100%", "18.75rem"]}
        display="flex"
        position="fixed"
        top="4rem"
        left={[open ? 0 : smallScreensLeft, 0]}
        minHeight={[null, "calc(100vh - 4rem)"]}
        fontSize={["sm", null]}
        transition="left 0.5s ease-in-out"
        zIndex={["2000", null]}
        py={5}
      >
        <VStack align="stretch" width="100%" gap={5}>
          {topMenu}
        </VStack>
        <VStack align="stretch" width="100%" gap={5} pt={[1, null]}>
          {bottomMenu}
        </VStack>
      </VStack>
    </>
  );
}

export function NavbarList({ children }) {
  return (
    <List spacing={3} fontWeight="700" overflow="hidden" pr={4}>
      {children}
    </List>
  );
}

export function NavbarItem({
  label,
  isActive,
  isNavbarItemDisabled,
  children,
  href = "",
  onClick = null,
}) {
  return (
    <ListItem
      borderTopRightRadius="full"
      borderBottomRightRadius="full"
      py={1}
      pl={3}
      pr={3}
      _hover={{
        backgroundColor: "brand.300",
        color: "white",
      }}
      backgroundColor={isActive ? "brand.300" : null}
      color={isActive ? "white" : null}
      opacity={isNavbarItemDisabled ? "0.25" : ""}
      pointerEvents={isNavbarItemDisabled ? "none" : "auto"}
    >
      <Link
        as={NextLink}
        onClick={onClick}
        display="flex"
        alignItems="center"
        gap={3}
        _hover={{ textDecoration: "none" }}
        href={href ? href : ""}
      >
        {children}
        {label}
      </Link>
    </ListItem>
  );
}

export function NavbarUserIcon({ name, image }) {
  return (
    <Avatar
      name={name}
      src={image}
      bg="brand.300"
      color="white"
      size="sm"
      fontWeight="bold"
      mr={1}
    />
  );
}

export function NavbarIcon({ icon, isUpdated }) {
  return (
    <Flex position={{ isUpdated } ? "relative" : null}>
      <ListIcon as={icon} boxSize="2em" />
      {isUpdated && (
        <Box
          as="span"
          display="block"
          height={2.5}
          width={2.5}
          bg="brand.500"
          borderRadius="full"
          position="absolute"
          right="6px"
        />
      )}
    </Flex>
  );
}
