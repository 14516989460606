import { tagAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    background: "brand.300",
    color: "white",
    borderRadius: 5,
    fontWeight: "700",
    padding: 2,
  },
  closeButton: {
    outline: "1px solid",
    opacity: 1,
    my: 1,
  },
});

const link = definePartsStyle({
  container: {
    transition: "0.25s ease-in-out",
    cursor: "pointer",
    _hover: {
      background: "brand.500",
    },
  },
});

const noCloseButton = definePartsStyle({
  closeButton: {
    display: "none",
  },
});

const gray = definePartsStyle({
  container: {
    background: "gray.base",
  },
});

const green = definePartsStyle({
  container: {
    background: "green.500",
  },
});

const orange = definePartsStyle({
  container: {
    background: "orange.500",
  },
});

const error = definePartsStyle({
  container: {
    background: "error",
  },
});

export const tagTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    noCloseButton,
    gray,
    green,
    orange,
    error,
    link,
  },
});
