import {ReadonlyUser} from "../core/User";
import {userIsGranted} from "./roleHierarchy";
import {isAwaitingCreationValidation, isAwaitingDeletion, isArchived, isAwaitingEditionValidation, ReadonlyContact} from "../core/Contact";

const RIGHT_CHECKER_MAP: {[key: string]: (user: ReadonlyUser, contact: ReadonlyContact) => boolean} = {
    'VIEW': canView,
    'EDIT': canEdit,
    'DELETE': canDelete,
    'VALIDATE_CREATION': canValidateCreation,
    'VALIDATE_EDITION': canValidateEdition,
    'VALIDATE_REMOVAL': canValidateRemoval,
};

export function isContactAllowedTo(user: ReadonlyUser, contact: ReadonlyContact, right: string): boolean {
    if(!Object.keys(RIGHT_CHECKER_MAP).includes(right)) {
        throw new Error(`Invalid right "${right}" when checking contact permission (id: ${contact.id})`);
    }

    return RIGHT_CHECKER_MAP[right](user, contact);
}

function canView(user: ReadonlyUser, contact: ReadonlyContact): boolean {
    return !isArchived(contact);
}

function canEdit(user: ReadonlyUser, contact: ReadonlyContact): boolean {
    if(isArchived(contact)) {
        return false;
    }

    return userIsGranted('ROLE_DSI', user) || !isAwaitingEditionValidation(contact);
}

function canDelete(user: ReadonlyUser, contact: ReadonlyContact): boolean {
    return !isArchived(contact);
}

function canValidateCreation(user: ReadonlyUser, contact: ReadonlyContact): boolean {
    return userIsGranted('ROLE_DSI', user) && isAwaitingCreationValidation(contact);
}

function canValidateEdition(user: ReadonlyUser, contact: ReadonlyContact): boolean {
    return userIsGranted('ROLE_DSI', user) && isAwaitingEditionValidation(contact);
}

function canValidateRemoval(user: ReadonlyUser, contact: ReadonlyContact): boolean {
    return userIsGranted('ROLE_DSI', user) && isAwaitingDeletion(contact);
}