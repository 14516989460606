import {radioAnatomy} from "@chakra-ui/anatomy";
import {createMultiStyleConfigHelpers} from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    borderColor: "transparent !important",
    backgroundColor: "blackAlpha.500",
  },
});

const primary = definePartsStyle({
  control: {
    _checked: {
      background: "brand.300",
      borderColor: "brand.300",
      _hover: {
        background: "brand.300",
        borderColor: "brand.300",
      },
    },
    _hover: {
      background: "brand.300",
      borderColor: "brand.300",
    },
    _invalid: {
      _checked: {
        background: "error",
        borderColor: "error",
      },
    },
  },
});

export const radioTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { primary },
  defaultProps: {
    variant: "primary",
  },
});
