import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    borderRadius: 5,
    border: "2px solid",
    backgroundColor: "transparent",
    fontWeight: "600",

    _invalid: {
      borderColor: "error",
      color: "error",
    },
  },

  icon: {
    color: "gray.base",
  },
});

const primary = definePartsStyle({
  field: {
    borderColor: "gray.base",
    color: "brand.300",

    _focus: {
      borderColor: "brand.300",
    },
  },
});

export const selectTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { primary },
  defaultProps: {
    variant: "primary",
  },
});
