import React from 'react';
import LoggedInLayout from "./LoggedInLayout";
import LoginHandler from "../login/LoginHandler";
import {useUserConnector} from "../../../hooks/authenticationManager";

export const LoggableLayout = ({
   children,
   loggedUser,
   isSuperAdminLogged,
   onLogOut,
   activeMenu = null,
   updatedMenu = null,
   hasNotification = false,
}: {
    children: React.ReactNode,
    loggedUser: any,
    isSuperAdminLogged: boolean,
    onLogOut: (e: Event) => void,
    activeMenu: string,
    updatedMenu: string,
    hasNotification?: boolean,
}) => {
    const connectUser = useUserConnector();

    return (
        <>
            {loggedUser ?
                <LoggedInLayout
                    loggedUser={loggedUser}
                    isSuperAdminLogged={isSuperAdminLogged}
                    onLogOut={onLogOut}
                    activeMenu={activeMenu}
                    updatedMenu={updatedMenu}
                    hasNotification={hasNotification}
                >
                    {children}
                </LoggedInLayout>
            : <LoginHandler onLoginSuccess={connectUser} />}
        </>
    );
};