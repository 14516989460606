import { ChakraProvider } from '@chakra-ui/react';
import theme from "../appTheme";
import Head from 'next/head';
import Layout from "../components/container/layout/Layout";
import {NextPage} from "next";
import {ReactElement, ReactNode} from "react";
import {AppProps} from "next/app";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {UserProvider} from "../contexts/UserProvider";
import("../assets/fonts/montserrat-v26-latin/stylesheet.css");
import("../assets/css/chakra-overwrites.css");

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout
}

function withProviders(page: ReactNode, pageProps: any) {
    const queryClient = new QueryClient();

    return (
        <ChakraProvider theme={theme}>
            <UserProvider isUnloggedPage={pageProps.isUnloggedPage}>
                <QueryClientProvider client={queryClient}>
                    {page}
                </QueryClientProvider>
            </UserProvider>
        </ChakraProvider>
    );
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
    const getLayout = Component.getLayout || ((page:ReactElement) : ReactNode => <Layout>{page}</Layout>)

    return withProviders(getLayout(
        <>
            <Head>
                <title>ILEC - CRM</title>
            </Head>
            <Component {...pageProps} />
        </>
    ), pageProps);
}

export default MyApp;
