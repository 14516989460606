import {User} from "./User";
import {ContactMailCampaign} from "./ContactMailCampaign";
import {CompanyMailCampaign} from "./CompanyMailCampaign";
import {GroupMailCampaign} from "./GroupMailCampaign";
import {Mail} from "./Mail";
import {UploadedFile} from "./File";
import {UploadableFile} from "../common/file/UploadableFile";

export enum MailCampaignStatus {
    DRAFT = 'DRAFT',
    SENT = 'SENT'
}

export enum MailCampaignType {
    RENEWAL = 'RENEWAL',
    CUSTOM = 'CUSTOM',
    PUBLIC_NEWSLETTER = 'PUBLIC_NEWSLETTER',
    MEMBER_NEWSLETTER = 'MEMBER_NEWSLETTER',
    FLASHILEC = 'FLASHILEC'
}

export interface MailCampaign {
    id: string;
    createdAt: string;
    updatedAt: string;
    displayType?: string;
    displayStatus?: string;
    mail: Mail;
    title: string;
    description: string;
    sentAt?: string;
    status: MailCampaignStatus;
    type: MailCampaignType;
    author: User;
    mailCampaignStatistics?: {
        mail_campaign_recipients_nb: number;
        mail_campaign_recipients_untracked_nb: number;
        mail_campaign_recipients_with_blocked_mail_nb: number;
        mail_campaign_recipients_with_open_mail_nb: number;
        mail_campaign_recipients_with_sent_mail_nb: number;
    };
    contactsMailCampaign: Array<ContactMailCampaign>;
    companiesMailCampaign: Array<CompanyMailCampaign>;
    groupsMailCampaign: Array<GroupMailCampaign>;
    sentEmailsNb?: number;
    renewalToAllAdherentCompanies: boolean;
}

export type ReadonlyMailCampaign = Readonly<MailCampaign>;

export interface MailCampaignDto {
    id: string;
    description: string;
    title: string;
    subject?: string;
    content?: string;
    authorId: string;
    attachments?: Array<UploadedFile | UploadableFile>;
    type?: MailCampaignType;
    groupIds?: Array<string>;
    contactIds?: Array<string>;
    companyIds: Array<string>;
    renewalToAllAdherentCompanies: boolean;
}

export function isMailCampaignSent(mailCampaign: ReadonlyMailCampaign): boolean {
    return mailCampaign.status === MailCampaignStatus.SENT;
}

export function isMailCampaignDraft(mailCampaign: ReadonlyMailCampaign): boolean {
    return mailCampaign.status === MailCampaignStatus.DRAFT;
}

export function isMailCampaignRenewal(mailCampaign: ReadonlyMailCampaign): boolean {
    return mailCampaign.type === MailCampaignType.RENEWAL;
}

export function isMailCampaignCustom(mailCampaign: ReadonlyMailCampaign): boolean {
    return mailCampaign.type === MailCampaignType.CUSTOM;
}

export function isMailCampaignCustomOrRenewal(mailCampaign: ReadonlyMailCampaign): boolean {
    return isMailCampaignCustom(mailCampaign) || isMailCampaignRenewal(mailCampaign);
}