import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const baseStyle = defineStyle({
  textTransform: "uppercase",
  fontWeight: 800,
  letterSpacing: 1,
  paddingTop: 0.5,
  paddingRight: 4,
  paddingBottom: 0.5,
  paddingLeft: 4,
  borderRadius: 5,
  transition: "all .2s ease-in-out",
});

const primary = defineStyle({
  border: "solid 2px",
  borderColor: "brand.300",
  color: "brand.300",
  _hover: {
    bg: "brand.300",
    color: "white",
  },
});

const white = defineStyle({
  border: "solid 2px",
  borderColor: "white",
  color: "white",
  _hover: {
    bg: "white",
    color: "brand.300",
  },
});

const secondary = defineStyle({
  border: "solid 2px",
  borderColor: "gray.600",
  color: "white",
  bg: "gray.600",
  _hover: {
    bg: "gray.900",
  },
});

const danger = defineStyle({
  border: "solid 2px",
  borderColor: "error",
  color: "white",
  bg: "error",
  _hover: {
    bg: "white",
    color: "error",
  },
});

const success = defineStyle({
  border: "solid 2px",
  borderColor: "green.500",
  color: "white",
  bg: "green.500",
  _hover: {
    bg: "white",
    color: "green.500",
  },
});

const outline = defineStyle({
  border: "solid 1px",
  borderColor: "brand.300",
  color: "brand.300",
});

const ghost = defineStyle({
  color: "brand.300",
});

const link = defineStyle({
  border: "none",
  _hover: {
    bg: "none",
    color: "inherit",
  },
});

export const buttonTheme = defineStyleConfig({
  baseStyle,
  variants: {
    primary,
    secondary,
    danger,
    success,
    outline,
    link,
    ghost,
    white,
  },
  defaultProps: {
    size: "sm",
    variant: "primary",
    colorScheme: "brand",
  },
});
