import React, {Dispatch, useContext, useEffect, useReducer} from 'react';
import {fetchCurrentUser} from "../lib/api/securityApi";
import {ReadonlyUser} from "../lib/core/User";
import {UserContext} from "../contexts/UserProvider";

const CONNECT_USER = 'CONNECT_USER';
const REFRESH_USER = 'REFRESH_USER';
const DISCONNECT_USER = 'DISCONNECT_USER';

export interface AuthenticationStatus {
    user: ReadonlyUser | null;
    disconnected: boolean;
    refreshed: boolean;
}

interface AuthenticationReducerAction {
    type: 'CONNECT_USER' | 'REFRESH_USER' | 'DISCONNECT_USER';
    user?: ReadonlyUser | null;
}

export interface AuthenticationManager {
    authenticationStatus: AuthenticationStatus;
    connectUser: (user: ReadonlyUser) => void;
    refreshUser: () => void;
    disconnectUser: () => void;
}

const authenticationReducer = (state: AuthenticationStatus, action: AuthenticationReducerAction) => {
    switch (action.type) {
        case CONNECT_USER:
            return {
                ...state,
                disconnected: false,
                user: action.user,
            };
        case REFRESH_USER:
            return {
                ...state,
                user: null,
            };
        case DISCONNECT_USER:
            return {
                ...state,
                disconnected: true,
                user: null,
            };
        default:
            return state;
    }
}

export function useAuthenticationManager(isUnloggedPage: boolean = false) : AuthenticationManager {
  const initialState: AuthenticationStatus = {
      disconnected: false,
      refreshed: false,
      user: null,
  }

  const connectUser = (user: ReadonlyUser) : void => dispatch({ type: CONNECT_USER, user });
  const refreshUser = () : void => dispatch({ type: REFRESH_USER });
  const disconnectUser = () : void => dispatch({ type: DISCONNECT_USER });

  const [ authenticationStatus, dispatch ] : [AuthenticationStatus, Dispatch<AuthenticationReducerAction>] = useReducer(authenticationReducer, initialState);

  useEffect(() => {
    if (
      isUnloggedPage ||
      authenticationStatus.user ||
      authenticationStatus.disconnected
    ) {
      return;
    }

    const getCurrentUser = async () => {
        const response = await fetchCurrentUser(['displayRoles']);

        if(!response.ok) {
            disconnectUser();
            return;
        }

        const user = await response.json();
        connectUser(user);
    };

    getCurrentUser()
        // in case of error, we disconnect the user so we avoid trying to fetch the user again and again
        .catch(() => disconnectUser());
  }, [authenticationStatus, isUnloggedPage]);

  return { authenticationStatus, connectUser, refreshUser, disconnectUser };
}

export function useCurrentUser() : ReadonlyUser | null {
    return useContext(UserContext)?.authenticationStatus.user;
}

export function useUserConnector() : () => void {
    return useContext(UserContext)?.connectUser;
}

export function useUserDisconnector() : () => void {
    return useContext(UserContext)?.disconnectUser;
}

export function useUserRefresher(): () => void {
    return useContext(UserContext)?.refreshUser;
}
