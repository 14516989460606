import {ReadonlyUser} from "../core/User";

// need to synchronize it with backend
const USER_HIERARCHY = {
    ROLE_DSI: [ 'ROLE_BRANDS', 'ROLE_ACCOUNTING', 'ROLE_NEWSLETTER', 'ROLE_SURVEY', 'ROLE_USER' ],
    ROLE_BRANDS: [ 'ROLE_USER' ],
    ROLE_ACCOUNTING: [ 'ROLE_USER' ],
    ROLE_NEWSLETTER: [ 'ROLE_USER' ],
    ROLE_SURVEY: [ 'ROLE_USER' ],
    ROLE_USER: [],
}

export function userIsGranted(role: string, user: ReadonlyUser) : boolean {
    if (userHasRole(role, user)) {
        return true;
    }

    const userRoles: Array<string> = user.roles;

    return userRoles.some(userRole =>
        USER_HIERARCHY[userRole]?.includes(role)
    ) as boolean;
}

export function userHasRole(role: string, user: ReadonlyUser) : boolean {
    return user.roles.includes(role);
}