import {ReadonlyUser} from "../core/User";
import {userIsGranted} from "./roleHierarchy";
import {
    isArchived,
    isAwaitingCreationValidation,
    ReadonlyAssistant,
    isAwaitingEditionValidation,
    isActive, isAwaitingDeletion
} from "../core/Assistant";

const RIGHT_CHECKER_MAP: {[key: string]: (user: ReadonlyUser, assistant: ReadonlyAssistant) => boolean} = {
    'VIEW': canView,
    'EDIT': canEdit,
    'DELETE': canDelete,
    'ARCHIVE': canArchive,
    'VALIDATE_CREATION': canValidateCreation,
    'VALIDATE_EDITION': canValidateEdition,
    'VALIDATE_REMOVAL': canValidateRemoval,
};

export function isAssistantAllowedTo(user: ReadonlyUser, assistant: ReadonlyAssistant, right: string): boolean {
    if(!Object.keys(RIGHT_CHECKER_MAP).includes(right)) {
        throw new Error(`Invalid right "${right}" when checking assistant permission (id: ${assistant.id})`);
    }

    return RIGHT_CHECKER_MAP[right](user, assistant);
}

function canView(user: ReadonlyUser, assistant: ReadonlyAssistant): boolean {
    return !isArchived(assistant);
}

function canEdit(user: ReadonlyUser, assistant: ReadonlyAssistant): boolean {
    return userIsGranted('ROLE_DSI', user) || (userIsGranted('ROLE_USER', user) && isActive(assistant))
}

function canDelete(user: ReadonlyUser, assistant: ReadonlyAssistant): boolean {
    return !isArchived(assistant) && userIsGranted('ROLE_DSI', user);
}

function canArchive(user: ReadonlyUser, assistant: ReadonlyAssistant): boolean {
    return !isArchived(assistant) && userIsGranted('ROLE_DSI', user);
}

function canValidateCreation(user: ReadonlyUser, assistant: ReadonlyAssistant): boolean {
    return userIsGranted('ROLE_DSI', user) && isAwaitingCreationValidation(assistant);
}

function canValidateEdition(user: ReadonlyUser, assistant: ReadonlyAssistant): boolean {
    return userIsGranted('ROLE_DSI', user) && isAwaitingEditionValidation(assistant);
}

function canValidateRemoval(user: ReadonlyUser, assistant: ReadonlyAssistant): boolean {
    return userIsGranted('ROLE_DSI', user) && isAwaitingDeletion(assistant);
}