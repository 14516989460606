import {Contact} from "./Contact";
import {Civility} from "./Civility";

export enum AssistantStatus {
    ACTIVE = 'ACTIVE',
    ARCHIVED = 'ARCHIVED',
    AWAITING_CREATION_VALIDATION = 'AWAITING_CREATION_VALIDATION',
    AWAITING_DELETION = 'AWAITING_DELETION',
    AWAITING_EDITION_VALIDATION = 'AWAITING_EDITION_VALIDATION'
}

export interface Assistant {
    id: string;
    createdAt: string;
    archivedAt: string;
    removalRequestDate: string;
    status: string;
    civility: Civility;
    displayCivility: string;
    displayStatus?: string;
    firstName: string;
    lastName: string;
    email: string;
    extranetAccess: boolean;
    contact?: Contact;
    hasLink?: boolean;
}

export type ReadonlyAssistant = Readonly<Assistant>;

export interface AssistantDto {
    civility: Civility;
    firstName: string;
    lastName: string;
    email: string;
    extranetAccess: boolean;
    contactId: string;
}

export type ReadonlyAssistantDto = Readonly<AssistantDto>;

export function isActive(assistant: ReadonlyAssistant): boolean {
    return assistant?.status === AssistantStatus.ACTIVE;
}

export function isAwaitingEditionValidation(assistant: ReadonlyAssistant): boolean {
    return assistant?.status === AssistantStatus.AWAITING_EDITION_VALIDATION;
}

export function isAwaitingCreationValidation(assistant: ReadonlyAssistant): boolean {
    return assistant?.status === AssistantStatus.AWAITING_CREATION_VALIDATION;
}

export function isArchived(assistant: ReadonlyAssistant): boolean {
    return assistant?.status === AssistantStatus.ARCHIVED;
}

export function isAwaitingAnyValidation(assistant: ReadonlyAssistant): boolean {
    return assistant?.status === AssistantStatus.AWAITING_CREATION_VALIDATION || assistant?.status === AssistantStatus.AWAITING_EDITION_VALIDATION;
}

export function isAwaitingDeletion(assistant: ReadonlyAssistant): boolean {
    return assistant?.status === AssistantStatus.AWAITING_DELETION;
}
