import {isArchived, ReadonlyCompany} from "../core/Company";
import {ReadonlyUser} from "../core/User";
import {userIsGranted} from "./roleHierarchy";

const RIGHT_CHECKER_MAP: {[key: string]: (user: ReadonlyUser, company: ReadonlyCompany) => boolean} = {
    'VIEW': canView,
    'EDIT': canEdit,
    'DELETE': canDelete,
    'ARCHIVE': canArchive,
};

export function isCompanyAllowedTo(user: ReadonlyUser, company: ReadonlyCompany, right: string): boolean {
    if(!Object.keys(RIGHT_CHECKER_MAP).includes(right)) {
        throw new Error(`Invalid right "${right}" when checking company permission (id: ${company.id})`);
    }

    return RIGHT_CHECKER_MAP[right](user, company);
}

function canView(user: ReadonlyUser, company: ReadonlyCompany): boolean {
    return !isArchived(company);
}

function canEdit(user: ReadonlyUser, company: ReadonlyCompany): boolean {
    if(isArchived(company)) {
        return false;
    }

    return userIsGranted('ROLE_DSI', user);
}

function canDelete(user: ReadonlyUser, company: ReadonlyCompany): boolean {
    return !isArchived(company) && userIsGranted('ROLE_DSI', user);
}

function canArchive(user: ReadonlyUser, company: ReadonlyCompany): boolean {
    return !isArchived(company) && userIsGranted('ROLE_DSI', user);
}