import React from "react";
import {
  Alert, AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  Link,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import {MdArrowBackIosNew, MdArrowForwardIos, MdPhone} from "react-icons/md";
import ilecLogo from "../assets/images/ilec-logo-la-voix-des-marques.svg";

export const Login = ({
  onSubmitLogin,
  onClickPasswordForgotten,
  onClickBackLogin,
  onSubmitRequestPasswordReset,
  loginError = null,
  forgottenPasswordError = null,
  requestPasswordResetSentAt = null,
  requestPasswordResetSent = false,
  isPasswordForgottenClicked = false,
}) => {
  return (
    <Flex
      minHeight="100vh"
      background="gray.700"
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        flexGrow={1}
        perspective="1000px"
        transition=".3s ease-in-out"
        style={{
          transformStyle: "preserve-3d",
        }}
        transform={
          isPasswordForgottenClicked ? "rotateY(180deg)" : "rotateY(-0deg)"
        }
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          backgroundColor="rgba(255,255,255,.95)"
          flexDirection="column"
          margin="1rem 2rem"
          minHeight={isPasswordForgottenClicked ? "20rem" : "39rem"}
          boxShadow="lg"
          maxWidth="28rem"
          flexGrow={1}
          position="relative"
          perspective="1000px"
          transition=".3s ease-in-out"
          transform={
            isPasswordForgottenClicked ? "rotateY(180deg)" : "rotateY(-0deg)"
          }
        >
          <Box
            id="loginFormUI"
            position="absolute"
            width="100%"
            padding={{ base: "1rem", md: "2rem" }}
            opacity={isPasswordForgottenClicked ? 0 : 1}
            transition="opacity .3s ease-in-out"
            zIndex={isPasswordForgottenClicked ? 1 : 2}
            pointerEvents={isPasswordForgottenClicked ? "none" : null}
          >
            <Heading
              as="h1"
              textAlign="center"
              marginTop="1.5em"
              marginBottom=".75em"
              fontSize="lg"
            >
              Bienvenue dans votre espace
              <Flex
                as="span"
                margin="1em 0 0"
                justifyContent="center"
                alignItems="flex-end"
              >
                <Image
                  src={ilecLogo}
                  alt="Logo Ilec"
                  width="10rem"
                  margin="0 1em 0 3em"
                />
                <Box as="span" color="brand.500">
                  CRM
                </Box>
              </Flex>
            </Heading>

            <Box width="100%" marginTop={10}>
              {loginError && (
                <Alert marginBottom={5} status='error'>
                  <AlertIcon />
                    {loginError}
                </Alert>
              )}
              <form onSubmit={onSubmitLogin}>
                <Stack spacing={3}>
                  <FormControl>
                    <FormLabel>Votre adresse email :</FormLabel>
                    <Input type="email" isRequired />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Votre mot de passe :</FormLabel>
                    <Input type="password" isRequired />
                  </FormControl>
                  <Flex
                    justifyContent="space-between"
                    flexWrap={{ base: "wrap", md: null }}
                    alignItems="center"
                  >
                    <Link
                      href="#"
                      id="password-forgotten"
                      textDecoration="underline"
                      // color={GRAY}
                      _hover={{ textDecoration: "none" }}
                      mb={{ base: "1rem", md: "0" }}
                      onClick={onClickPasswordForgotten}
                    >
                      Mot de passe oublié ?
                    </Link>
                    <Button
                      type="submit"
                      width="auto"
                      colorScheme="brand"
                      rightIcon={<MdArrowForwardIos />}
                      py={6}
                    >
                      Connexion
                    </Button>
                  </Flex>
                </Stack>
              </form>
            </Box>
          </Box>
          <Box
            id="passwordForgottenUI"
            padding={{ base: "1rem", md: "3rem 2rem" }}
            opacity={isPasswordForgottenClicked ? 1 : 0}
            transition="opacity .3s ease-in-out"
            zIndex={isPasswordForgottenClicked ? 2 : 1}
          >
            {requestPasswordResetSent && (
              <Text textAlign="center" mb="1rem">
                Si vous avez un compte associé au mail <strong>{requestPasswordResetSentAt}</strong> veuillez vérifier votre messagerie pour réinitialiser votre mot de passe.
              </Text>
            )}
            {!requestPasswordResetSent && (
              <>
                {forgottenPasswordError && (
                  <Alert marginBottom={5} status='error'>
                    <AlertIcon />
                    {forgottenPasswordError}
                  </Alert>
                )}
                <Text textAlign="center" mb="1rem">
                  Saisissez votre <strong>identifiant ou adresse email</strong>.
                </Text>
                <Text textAlign="center" mb="1rem">
                  Nous vous enverrons un lien de récupération de mot de passe
                  par email&nbsp;:
                </Text>
                <form onSubmit={onSubmitRequestPasswordReset}>
                  <VStack spacing={4} my={8}>
                    <Input type="email" required />
                    <Button type="submit" colorScheme="brand" py={6}>
                      Valider
                    </Button>
                  </VStack>
                </form>
              </>
            )}
            <br />
            <Center>
              <Link
                href="#"
                id="back-login"
                textDecoration="underline"
                _hover={{ textDecoration: "none" }}
                onClick={onClickBackLogin}
              >
                <Icon as={MdArrowBackIosNew} /> Retour à la page de connexion
              </Link>
            </Center>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};
