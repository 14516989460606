import {ReadonlyUser} from "../core/User";
import {isMailCampaignSent, ReadonlyMailCampaign} from "../core/MailCampaign";

const RIGHT_CHECKER_MAP: { [key: string]: (user: ReadonlyUser, mailCampaign: ReadonlyMailCampaign) => boolean } = {
    'VIEW': canView,
    'EDIT': canEdit,
    'DELETE': canDelete,
    'LAUNCH': canLaunch,
};

export function isMailCampaignAllowedTo(user: ReadonlyUser, mailCampaign: ReadonlyMailCampaign, right: string): boolean {
    if (!Object.keys(RIGHT_CHECKER_MAP).includes(right)) {
        throw new Error(`Invalid right "${right}" when checking mailCampaign permission (id: ${mailCampaign.id})`);
    }

    return RIGHT_CHECKER_MAP[right](user, mailCampaign);
}

function canView(user: ReadonlyUser, mailCampaign: ReadonlyMailCampaign): boolean {
    return true;
}

function canEdit(user: ReadonlyUser, mailCampaign: ReadonlyMailCampaign): boolean {
    if (isMailCampaignSent(mailCampaign)) {
        return false;
    }

    return true;
}

function canDelete(user: ReadonlyUser, mailCampaign: ReadonlyMailCampaign): boolean {
    if (isMailCampaignSent(mailCampaign)) {
        return false;
    }

    return true;
}

function canLaunch(user: ReadonlyUser, mailCampaign: ReadonlyMailCampaign): boolean {
    if (isMailCampaignSent(mailCampaign)) {
        return false;
    }

    return true;
}
