import {ReadonlyUser} from "../core/User";
import {isEventFinished, ReadonlyEvent} from "../core/Event";

const RIGHT_CHECKER_MAP: {[key: string]: (user: ReadonlyUser, event: ReadonlyEvent) => boolean} = {
    'VIEW': canView,
    'EDIT': canEdit,
    'DELETE': canDelete
};

export function isEventAllowedTo(user: ReadonlyUser, event: ReadonlyEvent, right: string): boolean {
    if(!Object.keys(RIGHT_CHECKER_MAP).includes(right)) {
        throw new Error(`Invalid right "${right}" when checking event permission (id: ${event.id})`);
    }

    return RIGHT_CHECKER_MAP[right](user, event);
}

function canView(user: ReadonlyUser, event: ReadonlyEvent): boolean {
    return true;
}

function canEdit(user: ReadonlyUser, event: ReadonlyEvent): boolean {
    return !isEventFinished(event);
}

function canDelete(user: ReadonlyUser, event: ReadonlyEvent): boolean {
    return !isEventFinished(event);
}