import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const outline = defineStyle({
  border: "2px solid",
  borderRadius: 5,
  borderColor: "gray.base",
  fontWeight: "600",
  color: "brand.300",
  _hover: {
    borderColor: "gray.base",
  },
  _focus: {
    borderColor: "brand.300",
  },
  _invalid: {
    border: "1px solid",
    borderColor: "error",
    color: "error",
  },
});

export const textareaTheme = defineStyleConfig({
  variants: { outline },
});
