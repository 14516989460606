import {Group} from "./Group";

export enum SectionStatus {
    ACTIVE = 'ACTIVE',
    ARCHIVED = 'ARCHIVED'
}

export interface Section {
    id: string;
    createdAt: string;
    title: string;
    status: SectionStatus;
    private: boolean;
    groups: Array<Group>;
    hasLink?: boolean | null;
    archivedAt: string | null;
}

export type ReadonlySection = Readonly<Section>;

export interface SectionDto {
    title: string;
    private: boolean;
    groupIds: Array<string>;
}

export function isArchived(section: ReadonlySection): boolean {
    return section?.status === SectionStatus.ARCHIVED;
}

export function isActive(section: ReadonlySection): boolean {
    return section?.status === SectionStatus.ARCHIVED;
}
