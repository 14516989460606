import {FileDto} from "./File";
import {Contact} from "./Contact";
import {Site} from "./Site";
import {Brand} from "./Brand";

export const ADHERENT = 'ADHERENT';
export const AFFILIATED = 'AFFILIATED';
export const ADDRESSEE = 'ADDRESSEE';

export const ARCHIVED = 'ARCHIVED';

export enum CompanyType {
    ADHERENT = 'ADHERENT',
    AFFILIATED = 'AFFILIATED',
    ADDRESSEE = 'ADDRESSEE'
}

export enum CompanyStatus {
    AWAITING_INFORMATION =  'AWAITING_INFORMATION',
    AWAITING_DSI_VALIDATION =  'AWAITING_DSI_VALIDATION',
    VALIDATED =  'VALIDATED',
    ARCHIVED =  'ARCHIVED'
}

export interface Company {
    id: string;
    createdAt: string;
    updatedAt: string;
    corporateName: string;
    corporateGroup: string;
    status: CompanyStatus;
    type: CompanyType;
    categories: Array<string>;
    phoneNumber: string;
    fax: string;
    websiteUrl: string;
    genericEmail: string;
    companyCreationDate: string;
    averageWorkforce: string;
    archivedAt: string;
    adherent: boolean;
    hasLink?: boolean | null;
    accessionDate: string;
    resignationDate: string;
    sitesNumber: number;
    adherentCompany?: Company,
    contacts: Array<Contact>;
    brands: Array<Brand>;
    affiliatedCompanies?: Array<Company>;
    sites?: Array<Site>;
    displayType?: string;
    displayStatus?: string;
    displayCategories?: Array<string>;
    translatedCategoriesMap?: { [key: string]: string}
    mainContact?: Contact;
    headquarters?: Site;
    logoUrl?: string;
    hdLogoUrl?: string;
    membershipMinuteUrl?: string;
    membershipLetterUrl?: string;
    resignationMinuteUrl?: string;
    resignationLetterUrl?: string;
    contactsNumber?: number;
    companyStatistics?: {[key: string]: {[key: string]: number }};
}

export type ReadonlyCompany = Readonly<Company>;

export interface ArchiveCompanyDto {
    resignationLetter: FileDto,
    resignationMinute: FileDto,
    archiveDate: string
}

export interface CompanyDto {
    corporateName: string,
    corporateGroup: string,
    companyType: string,
    logo: FileDto,
    membershipLetter: FileDto,
    membershipMinute: FileDto,
    hdLogo: FileDto,
    adherentCompanyId: string,
    averageWorkforce: string,
    categories: Array<string>
    phoneNumber: string,
    fax: string,
    websiteUrl: string,
    genericEmail: string,
    companyCreationDate: string,
    accessionDate: string,
    sitesNumber: number,
    headquarters: {
        address: {
            address: string,
            additionalAddress: string,
            postCode: string,
            town: string,
            country: string,
        },
        comments: string
    }
}

export interface SetCompanyMainContactDto {
    mainContactId: string
}

export function isArchived(company: ReadonlyCompany) {
    return company.status === ARCHIVED;
}

export function isAdherentCompany(company: ReadonlyCompany) {
    return company.type === CompanyType.ADHERENT;
}