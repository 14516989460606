import {Dispatch, useReducer} from "react";
import {Box} from "@chakra-ui/react";
import { Login } from "../../Login";
import {logIn, requestPasswordReset} from "../../../lib/api/securityApi";
import {ReadonlyUser} from "../../../lib/core/User";

const LOGIN_ERROR = 'LOGIN_ERROR';
const REMOVE_LOGIN_ERROR = 'REMOVE_LOGIN_ERROR';
const PASSWORD_FORGOTTEN_CLICKED = 'PASSWORD_FORGOTTEN_CLICKED';
const LOGIN_CLICKED_BACK = 'LOGIN_CLICKED_BACK';
const PASSWORD_RESET_REQUESTED_ERROR = 'PASSWORD_RESET_REQUESTED_ERROR';
const REMOVE_PASSWORD_RESET_REQUESTED_ERROR = 'REMOVE_PASSWORD_RESET_REQUESTED_ERROR';
const PASSWORD_RESET_SENT = 'PASSWORD_RESET_SENT';

interface LoginState {
    login: {
        error: string | null;
    };
    forgottenPassword: {
        active: boolean;
        error: string | null;
        passwordResetSent: boolean;
        passwordResetSentAt: string | null
    }
}

interface LoginAction {
    type: 'LOGIN_ERROR' | 'REMOVE_LOGIN_ERROR' | 'PASSWORD_FORGOTTEN_CLICKED' |  'LOGIN_CLICKED_BACK' | 'PASSWORD_RESET_REQUESTED_ERROR' | 'REMOVE_PASSWORD_RESET_REQUESTED_ERROR' | 'PASSWORD_RESET_SENT';
    message?: string;
}

const reducer = (state, action) => {
    switch (action.type) {
        case LOGIN_ERROR:
            return {
                ...state,
                login: {
                    ...state.login,
                    error: action.message,
                },
            };
        case REMOVE_LOGIN_ERROR:
            return {
                ...state,
                login: {
                    ...state.login,
                    error: null,
                },
            }
        case PASSWORD_RESET_REQUESTED_ERROR:
            return {
                ...state,
                forgottenPassword: {
                    ...state.forgottenPassword,
                    error: action.message,
                }
            };
        case REMOVE_PASSWORD_RESET_REQUESTED_ERROR:
            return {
                ...state,
                forgottenPassword: {
                    ...state.forgottenPassword,
                    error: null,
                }
            };
        case PASSWORD_FORGOTTEN_CLICKED:
            return {
                ...state,
                forgottenPassword: {
                    ...state.forgottenPassword,
                    active: true,
                }
            };
        case LOGIN_CLICKED_BACK:
            return {
                ...state,
                forgottenPassword: {
                    ...state.forgottenPassword,
                    active: false,
                    error: null,
                    passwordResetSent: false,
                    passwordResetSentAt: null
                }
            };
        case PASSWORD_RESET_SENT:
            return {
                ...state,
                forgottenPassword: {
                    ...state.forgottenPassword,
                    passwordResetSent: true,
                    passwordResetSentAt: action.message
                }
            };
        default:
            return state;
    }
}

export default function LoginHandler({onLoginSuccess} : {onLoginSuccess: (user: ReadonlyUser) => void}) {
    const initialState = {
        login: {
            error: null
        },
        forgottenPassword: {
            active: false,
            error: null,
            passwordResetSent: false,
            passwordResetSentAt: null
        }
    }
    const [ state, dispatch ] : [ LoginState, Dispatch<LoginAction> ] = useReducer(reducer, initialState);

    return (
        <Box minHeight="100vh">
            <Login
                loginError={state.login.error}
                forgottenPasswordError={state.forgottenPassword.error}
                isPasswordForgottenClicked={state.forgottenPassword.active}
                requestPasswordResetSent={state.forgottenPassword.passwordResetSent}
                requestPasswordResetSentAt={state.forgottenPassword.passwordResetSentAt}
                onSubmitLogin={async (e) => {
                    e.preventDefault();

                    const [email, password] = [e.target[0].value, e.target[1].value];

                    const response = await logIn(email?.trim(), password?.trim());
                    if (response.ok) {
                        dispatch({ type: REMOVE_LOGIN_ERROR });
                        const user = await response.json();
                        onLoginSuccess(user);

                        return;
                    }

                    let error = null;
                    try {
                        error = await response.json();
                    } catch(error) {
                        dispatch({ type: LOGIN_ERROR, message: "Une erreur est survenue lors de l'authentification." });

                        return;
                    }

                    dispatch({ type: LOGIN_ERROR, message: error.error });
                }}
                onClickPasswordForgotten={(e) => {
                    e.preventDefault();

                    dispatch({ type: PASSWORD_FORGOTTEN_CLICKED });
                }}
                onClickBackLogin={(e) => {
                    e.preventDefault();

                    dispatch({ type: LOGIN_CLICKED_BACK });
                }}
                onSubmitRequestPasswordReset={async (e) => {
                    e.preventDefault();

                    const email = e.target[0].value;
                    const response = await requestPasswordReset(email?.trim());

                    if (response.ok) {
                        dispatch({ type: REMOVE_PASSWORD_RESET_REQUESTED_ERROR });
                        dispatch({ type: PASSWORD_RESET_SENT, message: email });

                        return;
                    }

                    dispatch({ type: PASSWORD_RESET_REQUESTED_ERROR, message: 'Veuillez vérifier votre email.' });
                }}
            />
        </Box>
    );
}
