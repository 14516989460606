import querystring from "qs";

export function buildRelationIncludesUrl(
    baseUrl: string,
    relationIncludes: Array<string> = [],
) : string {
    if(!relationIncludes.length) {
        return baseUrl;
    }

    const joinedRelationIncludes = relationIncludes.join(',');

    return `${baseUrl}?${querystring.stringify({
        includes: joinedRelationIncludes
    })}`;
}