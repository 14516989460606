import {ReadonlyUser} from "../core/User";
import {isContactAllowedTo} from "./contactPermissionChecker";
import {isCompanyAllowedTo} from "./companyPermissionChecker";
import {isAssistantAllowedTo} from "./assistantPermissionChecker";
import {isEventAllowedTo} from "./eventPermissionChecker";
import {isMailCampaignAllowedTo} from "./mailCampaignPermissionChecker";
import {isSectionAllowedTo} from "./sectionPermissionChecker";

const RESOURCE_PERMISSION_CHECKER_MAP: {[key: string]: (user: ReadonlyUser, resource: any, right: string) => boolean} = {
    contact: isContactAllowedTo,
    company: isCompanyAllowedTo,
    assistant: isAssistantAllowedTo,
    event: isEventAllowedTo,
    mailCampaign: isMailCampaignAllowedTo,
    section: isSectionAllowedTo
};

export function isUserAllowedTo(user: ReadonlyUser, resourceName: string, right: string) {
    if(!Object.keys(RESOURCE_PERMISSION_CHECKER_MAP).includes(resourceName)) {
        throw new Error(`Invalid right "${right}" when checking resource "${resourceName}".`);
    }

    return {
        on: (resource: any) => {
            return RESOURCE_PERMISSION_CHECKER_MAP[resourceName](user, resource, right);
        }
    };
}
