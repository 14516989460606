import React, {useMemo} from "react";
import {LoggableLayout} from "./LoggableLayout";
import {logOut} from "../../../lib/api/securityApi";
import {ReadonlyUser} from "../../../lib/core/User";
import {useCurrentUser, useUserDisconnector} from "../../../hooks/authenticationManager";

export default function Layout({ children, updatedMenu, activeMenu }: { children: React.ReactNode, updatedMenu?: string, activeMenu?: string}) {
    const user: ReadonlyUser = useCurrentUser();
    const disconnectUser: () => void = useUserDisconnector();
    const isSuperAdminLogged: boolean = useMemo(() => {
        return user?.roles.includes('ROLE_DSI');
    }, [user]);

    return (
        <LoggableLayout
            updatedMenu={updatedMenu}
            activeMenu={activeMenu}
            loggedUser={user}
            isSuperAdminLogged={isSuperAdminLogged}
            onLogOut={async (e: Event) => {
                e.preventDefault();

                const response = await logOut();
                if(response.ok) {
                    disconnectUser();
                }
            }}>
            {children}
        </LoggableLayout>
    );
}