import { extendTheme } from "@chakra-ui/react";
import { buttonTheme } from "./components/Button";
import { inputTheme } from "./components/Input";
import { tagTheme } from "./components/Tag";
import { radioTheme } from "./components/Radio";
import { selectTheme } from "./components/Select";
import { checkboxTheme } from "./components/Checkbox";
import { textareaTheme } from "./components/Textarea";
import { switchTheme } from "./components/Switch";
import { numberInputTheme } from "./components/NumberInput";

const theme = extendTheme({
  breakpoints: {
    base: "0em", // 0px
    sm: "48em", // portrait tablets
    md: "64em", // landing tablets
    lg: "75em", // desktop 1200px
    xl: "90em", // desktop 1440px
    "2xl": "100em", // desktop 1600px
  },
  shadows: {
    base: "0px 0px 20px 1px rgba(0, 0, 0, 0.05)",
  },
  colors: {
    black: "#333",
    error: "#c11",

    gray: {
      50: "#F0F0F0",
      100: "#eee",
      200: "#E2E8F0",
      300: "#CBD5E0",
      400: "#A0AEC0",
      500: "#718096",
      600: "#4A5568",
      700: "#2D3748",
      800: "#1A202C",
      900: "#171923",
      baseLight: "#D3D3D3",
      base: "#9B9B9B",
    },
    red: {
      50: "#fdd",
      100: "#fbb",
      200: "#f88",
      300: "#f66",
      400: "#e33",
      500: "#c11",
      600: "#a22",
      700: "#833",
      800: "#622",
      900: "#500",
    },
    brand: {
      // Same as "red" for Ilec primary color is red :)
      50: "#fdd",
      100: "#fbb",
      200: "#f88",
      300: "#f66",
      400: "#e33",
      500: "#c11",
      600: "#a22",
      700: "#833",
      800: "#622",
      900: "#500",
    },
    border: {
      _light: "pink",
      _dark: "pink",
    },
  },
  uiWidth: "100%",
  gutter: {
    base: "1rem",
    sm: "1rem",
    md: "1rem",
    lg: "2rem",
    xl: "2rem",
    "2xl": "2rem",
  },
  fonts: {
    body: '"Montserrat", sans-serif',
    heading: '"Montserrat", "Arial", sans-serif',
    mono: '"Courrier New", monospace',
  },
  config: {
    cssVarPrefix: "chakra-ilec",
  },

  styles: {
    global: (props) => ({
      "html, body": {
        margin: 0,
        padding: 0,
      },
      body: {
        color: props.colorMode === "dark" ? "white" : "gray.800",
        bg: props.colorMode === "dark" ? "gray.800" : "#fff",
      },
    }),
  },
  layerStyles: {
    shadowed: {
      boxShadow: "0 0 .5em rgba(0,0,0,.2)",
    },
  },
  components: {
    Button: buttonTheme,
    Input: inputTheme,
    Tag: tagTheme,
    Radio: radioTheme,
    Select: selectTheme,
    Checkbox: checkboxTheme,
    Textarea: textareaTheme,
    Switch: switchTheme,
    NumberInput: numberInputTheme,
  },
  track: {
    bg: 'gray.100',
    _checked: {
      bg: "#008000",
    },
  },
});

export default theme;
