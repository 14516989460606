import React, {useCallback} from 'react';
import {useCurrentUser} from "./authenticationManager";
import {userHasRole, userIsGranted} from "../lib/security/roleHierarchy";
import {isUserAllowedTo} from "../lib/security/permissionManager";
import {ReadonlyUser} from "../lib/core/User";

export interface UserPermissions {
    hasRole: (role: string) => boolean;
    isGranted: (role: string) => boolean;
}

export interface UserActions {
    isAllowedTo: (resource: any, right: string) => boolean;
}

export function useUserPermission() : UserPermissions {
  const user: ReadonlyUser = useCurrentUser();

  const hasRole: (role: string) => boolean = useCallback((role: string) => userHasRole(role, user), [user]);
  const isGranted: (role: string) => boolean = useCallback((role: string) => userIsGranted(role, user), [user]);

  return { hasRole, isGranted };
}

export function useUserActions(resourceName: string): UserActions {
    const user: ReadonlyUser = useCurrentUser();
    const isAllowedTo: (resource: any, right: string) => boolean = useCallback((resource: any, right: string) => {
        if(!resource) {
            return false;
        }

        return isUserAllowedTo(user, resourceName, right).on(resource)
    }, [user, resourceName]);

    return {
        isAllowedTo
    };
}