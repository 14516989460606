import React from "react";
import {AuthenticationManager, useAuthenticationManager} from "../hooks/authenticationManager";
import dynamic from "next/dynamic";
const LoginPage = dynamic(() => import('../components/container/login/LoginHandler'));

export const UserContext = React.createContext(null as any);
export function UserProvider({ children, isUnloggedPage = false}: { children: React.ReactNode, isUnloggedPage: boolean}) {
    const authenticationManager : AuthenticationManager = useAuthenticationManager(isUnloggedPage);
    const authenticationStatus  = authenticationManager.authenticationStatus;

    if(isUnloggedPage) {
        return (
            <UserContext.Provider value={authenticationManager}>
                {children}
            </UserContext.Provider>
        );
    }

    if(authenticationStatus.disconnected) {
        return <LoginPage onLoginSuccess={authenticationManager.connectUser} />;
    }

    if(!authenticationStatus.user) {
        return <h3>Chargement en cours...</h3>;
    }

    return (
        <UserContext.Provider value={authenticationManager}>
            {children}
        </UserContext.Provider>
    );
}