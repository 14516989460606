import {ReadonlyUser} from "../core/User";
import {isArchived, ReadonlySection} from "../core/Section";
import {userIsGranted} from "./roleHierarchy";

const RIGHT_CHECKER_MAP: { [key: string]: (user: ReadonlyUser, section: ReadonlySection) => boolean } = {
    'VIEW': canView,
    'EDIT': canEdit,
    'DELETE': canDelete
};

export function isSectionAllowedTo(user: ReadonlyUser, section: ReadonlySection, right: string): boolean {
    if (!Object.keys(RIGHT_CHECKER_MAP).includes(right)) {
        throw new Error(`Invalid right "${right}" when checking section permission (id: ${section.id})`);
    }

    return RIGHT_CHECKER_MAP[right](user, section);
}

function canView(user: ReadonlyUser, section: ReadonlySection): boolean {
    return true;
}

function canEdit(user: ReadonlyUser, section: ReadonlySection): boolean {
    if (isArchived(section)) {
        return false;
    }

    return userIsGranted('ROLE_DSI', user);
}

function canDelete(user: ReadonlyUser, section: ReadonlySection): boolean {
    if (isArchived(section)) {
        return false;
    }

    return userIsGranted('ROLE_DSI', user);
}
